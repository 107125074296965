import {get,put,del,post} from '../../utils/request'

/**
 * 获取全部案例
 */
export function findCaseByPageApp(params){
    return get(
        '/case/findCaseByPageApp',
        params
    )
}
/**
 * 获取我的案例
 */
export function findMyCaseByPage(params){
    return get(
        '/case/findMyCaseByPage',
        params
    )
}

/**
 * 根据id查找案例
 */
export function findCaseById(id){
    
    return get(
        `/case/findCaseByIdApp/${id}`,
    )
}
/**
 * 修改案例
 */
export function updateCase(params){
    return put(
        '/case/updateCaseApp',
        params
    )
}
/**
 * 创建案例
 */
export function createCase(params){
    return post(
        '/case/createCase',
        params
    )
}
/**
 * 删除案例
 */
export function deleteCase(id){
    return del(
       `/case/deleteCaseApp/${id}`,
    )
}
/**
 * 
 */
export function deleteCardCaseApp(id){
    return del(
       `/case/deleteCardCaseApp/${id}`,
    )
}
/**
 * 获取户型
 */
export function findCaseType(){
    return get(
        '/case/findCaseType'
    )
}

/**
 * 获取类型
 */
export function findCaseCategory(){
    return get(
        '/case/findCaseCategory'
    )
}
/**
 * 获取随机id
 */
export function isCaseBelongUser(id){
    return get(
       `/case/isCaseBelongUser/${id}`,
    )
}

/**
 * 案例浏览数
 */
export function addNum(id,type){
    return put(
        `/case/addNum/${id}/${type}`,
    )
}
/**
 * 创建咨询
 */
export function createCaseConsult(params){
    return post(
        '/article/createConsult',
        params
    )
}
/**
 * 获取二维码地址
 */
export function getCaseDetailsUrl(caseId){
    return get(
        `/case/getCaseDetailsUrl/2/${caseId}`
    )
}

