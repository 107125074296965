import { post } from '@/utils/request';
/**
 * 获取公费电话详情
 * @param params
 */
export function addClue(param) {
  return post(
    '/user/clue/addClue',
    param,
    false
  );
}

export function addClueBehaviorInfo(param){
  return post(
    '/user/clue/addClueBehaviorInfo',
    param,
      false
  )
}
