import {getWxJsSign} from "../api/common";
import {addClueBehaviorInfo} from "../api/user/clue";

export const initWechatShare = async ({title, desc, url, imgUrl, openId, clueId, type}) => {
    let config = {
        debug: false,
        jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData','onMenuShareAppMessage','onMenuShareTimeline']
    }
    let data = await getWxJsSign({url: window.location.href})
    config = Object.assign(config, data)
    config.nonceStr = data.noncestr
    // eslint-disable-next-line no-undef
    await wx.config(config)
    // eslint-disable-next-line no-undef
    wx.ready(function () {
        // eslint-disable-next-line no-undef
        // wx.updateAppMessageShareData({
        //     title: title, // 分享标题
        //     desc: desc, // 分享描述
        //     link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        //     imgUrl: imgUrl, // 分享图标
        //     success: function () {
        //         // 设置成功
        //     }
        // })
        // // eslint-disable-next-line no-undef
        // wx.updateTimelineShareData({
        //     title: title, // 分享标题
        //     link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        //     imgUrl: imgUrl, // 分享图标
        //     success: function () {
        //         // 设置成功
        //     }
        // })
        // eslint-disable-next-line no-undef
        wx.onMenuShareAppMessage({
            title: title, // 分享标题
            desc: desc, // 分享描述
            link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: imgUrl, // 分享图标
            success: function () {
                // 用户点击了分享后执行的回调函数
                if (clueId) {
                    addClueBehaviorInfo({clueId, openId, type});
                }
            }
        });

        // eslint-disable-next-line no-undef
        wx.onMenuShareTimeline({
            title: title, // 分享标题
            link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: imgUrl, // 分享图标
            success: function () {
                // 用户点击了分享后执行的回调函数
                if (clueId) {
                    addClueBehaviorInfo({clueId, openId, type});
                }
            }
        })
    });
}
